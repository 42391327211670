<template>
  <div class="add_con titlesTop">
    <headTitle title="证书详情" />
    <div v-if="bookInfo" class="add_fen">
      <div class="add_tit">审核状态：</div>
      <span class="add_spn">{{ bookInfo.status | showStatus }}</span>
    </div>
    <div class="add_fen">
      <div class="add_tit">证书分类：</div>
      <span class="add_spn" @click="openClass">{{
        classify ? classify : "选择分类"
      }}</span>
    </div>
    <div v-if="bookInfo" class="add_fen">
      <div class="add_tit">审核意见：</div>
      <span class="add_spn">{{ bookInfo.aduitText }}</span>
    </div>
    <div class="add_img">
      <div class="add_tit">上传图片:</div>
      <div v-if="bookInfo">
        <img class="bok_img" :src="fileUrl" alt="" @click="onFileClick" />
        <van-uploader
          v-show="false"
          class="bok_upl"
          id="cameraFile"
          v-model="fileList"
          accept="image/*"
          :before-read="beforeRead"
          :after-read="afterRead"
        >
        </van-uploader>
      </div>
    </div>
    <div class="add_btm" v-if="isModfiy">
      <div class="add_btn" @click="submitBook">修改重新上传</div>
    </div>

    <!-- 证书分类 -->
    <van-action-sheet v-model="showPicker" :round="false" title="选择证书分类">
      <van-radio-group class="cls_wra" v-model="radio">
        <van-radio
          class="cls_itm"
          v-for="item in columns"
          :key="item.id"
          :name="item.certificateTypeName"
          @click="onSelect(item)"
          >{{ item.certificateTypeName }}</van-radio
        >
      </van-radio-group>
    </van-action-sheet>
  </div>
</template>

<script>
import { Toast } from "vant";
import PersonApi from "@/api/person";
import InteractionApi from "@/api/interaction";

export default {
  data() {
    return {
      fileList: [],
      showPicker: false,
      columns: [],
      classify: "",
      radio: "",
      fileUrl: "",
      bookClassId: "", // 证书分类ID
      bookInfo: null,
      isModfiy: false, // 被驳回才能修改重新上传
    };
  },
  filters: {
    showStatus(value) {
      if (value == 1) {
        return "审核中";
      } else if (value == 2) {
        return "已通过";
      } else if (value == 3) {
        return "已驳回";
      }
    },
  },
  mounted() {
    // console.log(this.$route.query);
    this.bookInfo = this.$route.query;
    this.classify = this.bookInfo.certificateTypeName;
    this.bookClassId = this.bookInfo.certificateTypeId;
    this.fileUrl = this.bookInfo.certificatePath;
    if (this.bookInfo.status == 3) {
      this.isModfiy = true;
    }
    this.queryBookClass();
  },
  methods: {
    // 查询证书类型
    queryBookClass() {
      PersonApi.getBookClass({ appName: this.appName })
        .then((res) => {
          if (res.success) {
            this.columns = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 打开上传
    onFileClick() {
      // console.log(this.fileList);
      if (this.isModfiy) {
        const doc = document.getElementById("cameraFile");
        return doc.click();
      }
    },

    // 资源上传校验，图片每张不超过8M，视频不超过20M
    beforeRead(file) {
      const imgMaxSize = 8 * 1024 * 1024;
      var arr = [];
      arr = file.type.split("/");
      const fileType = arr[0];
      // 获取后缀名
      let index = file.name.lastIndexOf(".");
      file.fileSuffix = file.name.substr(index + 1);
      if (fileType !== "image") {
        Toast("请上传图片!");
        return false;
      }
      if (file.size > imgMaxSize) {
        Toast("图片大小不能超过8M");
        return false;
      }

      return true;
    },

    afterRead(file) {
      //   console.log(file);
      file.status = "uploading";
      file.message = "上传中...";
      var formData = new FormData();
      formData.append("file", file.file);
      const params = {
        deviceNumber: "15665360907", // 设备号
        mediaSuffix: file.file.fileSuffix, // 文件后缀
      };
      InteractionApi.upLoadImg(formData, params)
        .then((res) => {
          // console.log(res);
          if (res.success) {
            this.fileUrl = res.data;
            this.fileList = [];
            file.status = "done";
            Toast("上传成功");
          }
        })
        .catch((err) => {
          console.log(err);
          file.status = "failed";
          file.message = "上传失败";
        });
    },

    // 打开分类
    openClass() {
      if (this.isModfiy) {
        this.showPicker = true;
      }
    },

    // 选择分类
    onSelect(value) {
      this.classify = value.certificateTypeName;
      this.bookClassId = value.id;
      this.showPicker = false;
    },

    // 修改证书申请
    submitBook() {
      if (!this.classify) {
        Toast("请选择分类");
        return;
      }
      if (!this.fileUrl) {
        Toast("请上传证书图片");
        return;
      }
      const params = {
        certificatePath: this.fileUrl,
        certificateTypeId: this.bookClassId,
        id: this.bookInfo.id,
        appName: this.appName,
      };
      PersonApi.modifyBook(params)
        .then((res) => {
          if (res.success) {
            Toast("上传成功，等待管理员审核");
            this.fileList = [];
            this.$router.back();
          } else {
            this.fileList = [];
            Toast(res.message);
          }
          // console.log(res);
        })
        .catch((err) => {
          this.fileList = [];
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.add_con {
  .add_fen {
    display: flex;
    padding: 10px;
    margin-top: 5px;
    // border-top: 1px solid #ececec;
    border-bottom: 1px solid #ececec;
    input {
      border: none;
    }
  }
  .add_img {
    padding: 15px 10px;
    display: flex;
    border-bottom: 1px solid #ececec;
    position: relative;
  }
  .add_btm {
    padding: 10px;
    margin-top: 50px;
    .add_btn {
      width: 100%;
      height: 45px;
      margin: 10px auto;
      line-height: 45px;
      color: #fff;
      background: #1377e2;
      text-align: center;
      border-radius: 5px;
    }
    span {
      font-size: 11px;
      color: #a5a5a5;
    }
  }
  .add_tit {
    // width: 10px;
    margin-right: 15px;
  }
  .add_spn {
    display: inline-block;
    width: 200px;
  }
  .bok_img {
    // position: absolute;
    width: 88px;
  }
  .bok_upl {
    position: absolute;
    z-index: -2;
  }
  .cls_wra {
    margin-left: 50px;
    height: 150px;
    overflow-y: auto;
    .cls_itm {
      padding: 5px 0;
    }
  }
}
</style>
